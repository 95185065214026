.booking-container {
  background-color: #f3f6fa;
  padding-bottom: 4rem;

  // width: '70rem';
}

.image-small {
  width: 1.7em;
  height: 1.7em;
  border-radius: 50%;
  margin: 2%;
}

.booking-title {
  margin-top: 3.5rem;
  margin-bottom: 1rem;
}

@media only screen and (max-width: 480px) {
  .second-title {
    justify-content: center;
  }

  .booking-title {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}

@media only screen and (max-width: 992px) {
  .booking-title {
    margin-left: 1rem;
    margin-right: 1rem;
  }
}
