.empty-container {
    background: #fff;
    padding: 20px;
    margin-top: 20px;
    border-radius: 10px;
    text-align: center;
    min-height: 600px;

    .empty-content {
        background: #FFF;
        filter: drop-shadow(0px 0px 13.6px rgba(0, 0, 0, 0.28));
        width: 70%;
        display: block;
        margin: 30px auto;
        border: 1px solid #e8e7e7;
        border-radius: 4px 0px 0px 0px;
        h2 {
            font-family: 'Mulish', sans-serif;
            font-size: 15px;
            font-weight: 700;
            color: #000;
            border-radius: 4px 0px 0px 0px;
            background: #F9F9F9;
            display: flex;
            gap: 14px;
            align-items: center;
            padding: 10px 40px;
            margin: 0px;
        }
        .clinic-details {
            border-radius: 12px;
            border: 1px solid #BEBEBE;
            background: #FFF;
            width: 60%;
            display: block;
            margin: 50px auto 30px auto;
            padding: 20px 40px;
            
            p {
                color: #000;
                font-weight: 700;
                font-size: 15px;
                text-align: left;
                font-family: 'Mulish', sans-serif;

            }
            ul{
                list-style-type: none;
                padding-left: 0px;
                li{
                    text-align: left;
                    font-family: 'Mulish', sans-serif;
                    display: flex;
                    align-items: center;
                    gap: 10px;
                    font-weight: 700;
                    color: #5F5F5F;
                    font-size: 14px;
                    svg{
                        width: 32px;
                    }
                }
            }
        }
    }

}