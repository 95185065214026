// @import '~antd/dist/antd.css';
// @import './assets/styles/antd.css';

.App {
  text-align: center;
  height: 100% !important;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.app-header {
  font-family: "'Poppins', sans-serif" !important;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

// .App-header {
//   background-color: #282c34;
//   min-height: 100vh;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   justify-content: center;
//   font-size: calc(10px + 2vmin);
//   color: white;
// }

.App-link {
  color: #61dafb;
}

.mobileVisible {
  display: none;
}

.mobileHidden {
  display: block;
}
.appoinment-card{
  width: 40%;
}
@media only screen and (min-width: 320px) and (max-width: 768px) { 
  .appoinment-card{
    width: 80%;
  }
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media only screen and (max-width: 767px) {
  .mobileVisible {
    display: block;
  }

  .mobileHidden {
    display: none;
  }
}

// success reg layout
.successPopup {
  width: 80%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.password-register-input{
  .ant-form-item-explain-error{
    text-align: left;
    padding-top: 5px;
  }
}

.ant-form-item-explain-error{
  text-align: left;
  padding-top: 5px;
}

#register {
  .ant-form-item-label {
    margin-left: 8.33333333%;
  }

  @media (max-width: 992px) {
    .ant-form-item-label {
      margin-left: 16.66666667%;
    }
  }
  @media (max-width: 575px){
    .ant-form-item-label {
      margin-left: 4.16666667%;
    }
  }
}
//Country Emoji
.emoji-font{
  font-family: "Noto Color Emoji", sans-serif;
}
.country-dropdown{
  width: 300px !important;
}
.country-select{
  .ant-select-selector{
  .country-name{
      display: none;
  }
  }
}