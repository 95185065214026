.site-calendar-customize-header-wrapper {
  .ant-picker-calendar-mini {
    border-radius: 12px !important;
  }
  .ant-picker-panel {
    border-radius: 0 0 12px 12px !important;
    border-top-color: rgba(161, 182, 218, 0.47) !important;
    border-top-width: 1.7px !important;
  }
  .ant-picker-date-panel {
    border-top-color: rgba(161, 182, 218, 0.47) !important;
    border-top-width: 0.2px !important;
    border-top-style: none !important;
    padding: 1rem 1rem !important;
  }

  .ant-picker-content thead tr th {
    font-weight: 900 !important;
  }
  // .ant-picker-cell-inner
  // .ant-picker-calendar-date {
  //   border-radius: 50% !important;
  // }
  // .ant-picker-calendar-date-today {
  //   border-radius: 50% !important;
  // }
}

.calendar-header {
  font-family: 'Poppins', sans-serif !important;
}

.calendar-container {
  margin-top: 5px;
}

@media only screen and (max-width: 480px) {
  .site-calendar-customize-header-wrapper {
    margin-left: 1rem;
    margin-right: 1rem;
  }
}

@media only screen and (max-width: 992px) {
  .site-calendar-customize-header-wrapper {
    margin-left: 1rem;
    margin-right: 1rem;
  }
}
