@media only screen and (max-width: 992px) {
  .header-logo {
    margin-left: 0.8rem;
  }
}

.header-logo {
  
  object-fit: contain;

  width: 110px;
  height: 110px;
  margin-top: -15px;
  margin-right: 2.1rem;

  &.clearx-logo{
    width: 11.1rem;
    height: 3rem;
  
    margin-top: 11px;
    margin-left: 1rem;
  }
}
