.booking-form-container {
  width: 100%;
  margin: 0 0 1rem 0;
}

.booking-form-title {
  margin: 1.5rem 0.5rem 1.5rem 2rem !important;

  font-family: 'Poppins', sans-serif !important;
}

.booking-header {
  font-family: 'Poppins', sans-serif !important;
}
.booking-form-title-container {
  width: 100%;
  border-bottom-style: solid;

  margin: 0 1rem 0 0;
  border-width: 1px;
  border-color: rgba(161, 182, 218, 0.47);
  border-radius: 12px 12px 0 0;

  background-color: white;
}

.booking-form {
  padding-top: 1rem;
  padding-left: 2rem;
  padding-right: 2%;
  border-radius: 12px;
  background-color: white;
  padding-bottom: 1.5rem;
}
.test {
  .ant-input:focus {
    border-style: none !important;
    border-color: white !important;
  }

  .ant-form-item {
    border-style: solid !important;
  }
}

@media only screen and (max-width: 992px) {
  .booking-form-container {
    margin-top: 1rem;
    margin-right: 1rem;
  }
}

@media only screen and (max-width: 480px) {
  .inline-form-left {
    display: block !important;
    width: auto !important;
    margin: 0 0.5rem 0 0 !important;
  }
  .inline-form-right {
    display: block !important;
    width: auto !important;
    margin: 2rem 0.5rem 1.5rem 0 !important;
  }
  .booking-form-title {
    margin: 1.5rem 1rem 1.5rem 1rem !important;
  }
  .booking-form {
    padding-left: 1rem;
  }
}
